import React, { useState } from 'react';
import { useInView } from "react-intersection-observer";
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import heroVideo from 'assets/images/offers/video1.mp4'
import consultingImage from 'assets/images/offers/consulting.jpg';
import cadImage from 'assets/images/offers/cad.jpg';
import softwareImage from 'assets/images/offers/software.jpg';
import training from 'assets/images/offers/training.jpg';
import cfd1 from 'assets/images/offers/cfd1.png'
import cfd2 from 'assets/images/offers/cfd2.png'
import automatedBom from 'assets/images/offers/automatedBom.png'
import './Offers.css';
import Hero from 'components/Hero/Hero';

const offers = [
  {
    title: 'Consulting',
    description: 'Expert guidance to streamline your PLM processes.',
    details: 'Our consulting services include workflow optimization, strategy development, and training programs to ensure your team is equipped for success. Business Process Consulting to ensure the tools available are used properly and efficiently for a trustworthy Bill of Materials.',
    image: consultingImage,
  },
  {
    title: 'CAD Integration',
    description: 'Seamless CAD solutions for your design needs.',
    details: 'We provide advanced CAD integration to enhance design workflows, including tool customization and interoperability solutions. CAD experts available to support with Reverse Engineering, CAD Modeling, Component Family Creation and Management, and more.',
    image: cadImage,
  },
  {
    title: 'App Development & Deployment',
    description: 'Custom tools tailored to your unique requirements, seemlessly integrated.',
    details: 'From concept to deployment, we develop PLM tools that align perfectly with your processes and goals to maximize your efficiency and minimize downtime. Plug and Play Applications will ensure your business has all the tools you need to be successful, without heavy customizations.',
    image: softwareImage,
  },
  {
    title: 'Training',
    description: 'Online training modules filled with micro-learnings to ensure all users have the resources they need to succeed.',
    details: "Unlock your team's potential with our cutting-edge online training service. Designed for professionals, our courses combine expert-led instruction with interactive content to deliver a dynamic and effective learning experience. Whether you're upskilling your workforce or onboarding new talent, our platform offers flexible, on-demand modules tailored to your industry needs.",
    image: training,
  },
];

const OurServices = () => {
  const [show, setShow] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (offer) => {
    setSelectedOffer(offer);
    setShow(true);
  };
  return (
    <section className="py-5" id="offer_section">
      <Container>
        <h2 className="text-center mb-4">Our Services</h2>
        <Row className='d-flex align-items-stretch'>
          {offers.map((offer, index) => (
            <Col md={6} lg={6} className="mb-4" key={index} data-aos="fade-up">
              <Card className="offer-card shadow-sm h-100">
                <Card.Img variant="top" src={offer.image} className="card-image" />
                <Card.Body>
                  <Card.Title>{offer.title}</Card.Title>
                  <Card.Text>{offer.description}</Card.Text>
                  <Button
                    variant="primary"
                    onClick={() => handleShow(offer)}
                    className="btn-learn-more"
                  >
                    Learn More
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      {/* Modal for Offer Details */}
      {selectedOffer && (
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{selectedOffer.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{selectedOffer.details}</p>
          </Modal.Body>
          <Modal.Footer>
            <a className="btn btn-primary learn-more-btn" href="/contact">Get in touch</a>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </section>
  );
};


const Project = ({ title, description, image, alt }) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.3 });

  return (
    <div
      className={`project-item ${inView ? "in-view" : ""} py-3`}
      ref={ref}
    >
      <img src={image} alt={alt} className="project-image" />
      <div className="project-description">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

const projects = [
  {
    title: "CFD / NVH / FEA Pipeline with CAD",
    description:
      "Utilizing advanced CAD modeling, this project showcases how our pipeline streamlines design validation and integrates with simulation tools. By automating complex simulations, we reduce manual effort and increase precision.",
    image: cfd1,
    alt: "CFD pipeline using CAD",
  },
  {
    title: "Automation for Efficiency",
    description:
      "This project demonstrates how automation accelerates testing cycles and enhances efficiency. By leveraging modern tools, we achieved a significant reduction in time-to-market.",
    image: cfd2,
    alt: "Automation for efficiency",
  },
  {
    title: "Automated BOM Extract ",
    description:
      "Streamline your workflow with our automated BOM loading solution, which integrates seamlessly with SharePoint and shared file locations to eliminate the need for unreliable Excel reports. This system automates the configuration and loading of Bills of Materials (BOMs), ensuring they are always up-to-date—whether refreshed nightly or on-demand by the user. By accelerating processes and enhancing data accuracy, it guarantees that your BOMs are ready for use whenever you need them.",
    image: automatedBom,
    alt: "Automated BOM Integration",
  },
];

const ProjectExamples = () => {
  return (
    <section className="project-examples">
      <div className='container'>
        <h2 className="section-title">Project Examples</h2>
        {projects.map((project, index) => (
          <Project
            key={index}
            title={project.title}
            description={project.description}
            image={project.image}
            alt={project.alt}
          />
        ))}
      </div>
    </section>
  );
};

const Offers = () => {
  return (
    <div>
      {/* Hero Section */}
      <Hero
        source={heroVideo}
        isVideo={true}
        title="Our Offers"
        subtitle="Explore our range of PLM consulting services tailored to your needs."
        buttonLink={'#offer_section'}
        buttonText="Learn More"
      />
      <OurServices />
      <ProjectExamples />
    </div>

  );
};

export default Offers;