import React from 'react';
import { Container, Row, Col, Accordion, Image } from 'react-bootstrap'; //Card, Carousel,
import './About.css';
import heroVideo from "assets/images/about/video1.mp4";
import companyImage from 'assets/images/about/company.jpg';
import teamImage from 'assets/images/about/team.jpg';
// import { teamMembers } from 'data/teamData';
import { linkedinPosts } from 'data/LinkedInPosts';
import Hero from "components/Hero/Hero";
import LinkedInCarousel from "components/LinkedInCarousel/LinkedInCarousel";

const CompanyOverview = () => {
  return (
    <section className="py-5 bg-light" id="company_section">
      <Container>
        <Row className="align-items-center">
          <Col md={6} data-aos="fade-right">
            <Image src={companyImage} fluid rounded />
          </Col>
          <Col md={6} data-aos="fade-left">
            <h2>Our Company</h2>
            <p>
              With years of expertise in PLM consulting, our mission is to help businesses achieve efficiency and
              innovation. From product design to deployment, we are your trusted partner in PLM transformation.
            </p>
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Our Mission</Accordion.Header>
                <Accordion.Body>
                  We aim to simplify product lifecycle management, empowering companies to focus on their core strengths.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Our Vision</Accordion.Header>
                <Accordion.Body>
                  To be the go-to partner for businesses looking to modernize and innovate their PLM strategies.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const HistorySection = () => {
  return (
    <section className="py-5 text-center">
      <Container>
        <h2>Our History</h2>
        <p>From humble beginnings to global recognition, here's our journey.</p>
        <Row>
          <Col md={10} className="mx-auto">
            <ul className="timeline">
              <li data-aos="fade-up">2021: Founded with a vision to optimize PLM processes.</li>
              <li data-aos="fade-up">2022: First tool release and team growth.</li>
              <li data-aos="fade-up">2024: Our partnership with you.</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const TeamSection = () => {
  return (
    <section className="py-5 bg-light">
      <Container>
        <h2 className="text-center mb-5">Meet Our Team</h2>
        <Row>
          <Col md={6} data-aos="fade-right">
            <Image src={teamImage} fluid rounded />
          </Col>
          <Col md={6} data-aos="fade-left">
            <p>
              Our team is a group of passionate experts dedicated to transforming PLM processes. We work together to
              ensure your success. At our core, we are a diverse team of skilled professionals dedicated to helping businesses 
              unlock the full potential of their systems. With over 60 cumulative years of CAD experience and 30 cumulative 
              years of expertise in PLM, we bring unparalleled knowledge and insight to every project. Our team consists of 
              industry specialists who have honed their skills at leading corporations, combining automation, effective business processes, 
              and innovative strategies to deliver results. We pride ourselves on being the partner you can trust to streamline operations, 
              enhance efficiency, and drive success in an ever-evolving landscape.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

// const MemberSection = () => {
//  return (
//   <section className="py-5 text-center">
//     <Container>
//       <h2 className="mb-4">Our Team Members</h2>
//       <Carousel>
//         {teamMembers.map((member, index) => (
//           <Carousel.Item key={index}>
//             <Card className="mx-auto" style={{ width: '18rem' }}>
//               <Card.Img variant="top" src={member.image} />
//               <Card.Body>
//                 <Card.Title>{member.name}</Card.Title>
//                 <Card.Text>{member.position}</Card.Text>
//                 <Card.Text className="text-muted">{member.bio}</Card.Text>
//               </Card.Body>
//             </Card>
//           </Carousel.Item>
//         ))}
//       </Carousel>
//     </Container>
//   </section>
//  );
// };

const AboutUs = () => {
  return (
    <div>
      {/* Hero Section */}
      <Hero
        source={heroVideo}
        isVideo={true}
        title="About Us"
        subtitle="Discover our story, our values, and the people behind our expertise."
        buttonLink={'#company_section'}
        buttonText="Learn More"
      />
      <CompanyOverview />
      <HistorySection />
      <TeamSection />
      <LinkedInCarousel postUrls={linkedinPosts} />
      {/* <MemberSection /> */}
    </div>
  );
};

export default AboutUs;
