import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from 'assets/images/shared/logo.svg';
import {FaLinkedin} from "react-icons/fa6";
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer bg-dark text-white mt-auto py-3">
            <Container>
                <Row>
                    <Col md={4} className="footer-left">
                        <figure>
                            <img 
                                src={logo} 
                                alt="Company Logo" 
                                className="footer-logo"
                            />
                            <figcaption className="text-muted">
                                True North PLM
                                <a 
                                    href="https://www.linkedin.com/company/true-north-plm" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="linkedin-icon"
                                >
                                    <FaLinkedin size={20} />
                                </a>
                            </figcaption>
                        </figure>
                    </Col>
                  
                    <Col md={4} className="footer-middle">
                        <div>
                            <h5>Sitemap Links</h5>
                            <ul className="list-unstyled">
                                <li><a href="/">Home</a></li>
                                <li><a href="/about">About Us</a></li>
                                <li><a href="/offers">Our Services</a></li>
                                <li><a href="/contact">Contact Us</a></li>
                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </Col>

                    <Col md={4} className="footer-right">
                        <div>
                            <h5>Contact Us</h5>
                            <ul className="list-unstyled">
                                {/* <li>Phone: TBD</li> */}
                                <li>Email: contact@truenorthplm.com</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row className="text-center mt-4">
                    <Col>
                        <p className="text-muted">© 2024 True North PLM - All Rights Reserved.</p>
                        {/* <p>&copy; {new Date().getFullYear()} True North PLM. All rights reserved.</p> */}
                    </Col>
                </Row>
            </Container>
        </footer>
    );
  };

export default Footer;
