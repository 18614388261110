import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert, Card } from 'react-bootstrap';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      to: "contact@truenorthplm.com",
      subject: "Contact Form Submission",
      body: `
        Name: ${formData.name}
        Email: ${formData.email}
        Message: ${formData.message}
      `,
    };
    
    try {
        const response = await fetch(
          "https://truenorthazurefunctionsappstore.azurewebsites.net/api/v1/SendEmail",
          {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "x-functions-key": "L3JMdnsOidgCnDF6tIIeU9G_gj1AZg9Jq_K5jN_AnYp3AzFuEQggvw==",
            },
            body: JSON.stringify(payload),
        });
    
        if (response.ok) {
          setSubmitted(true);
          setFormData({ name: '', email: '', message: '' });
          setTimeout(() => setSubmitted(false), 5000);
        } else {
          const errorText = await response.text();
          alert(`Failed to send message. Please try again. Error: ${errorText}`);
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        alert('Error submitting form. Please try again.');
      }
    };

  return (
    <Container className="contact-page mt-5">
      <Row className="mb-5 text-center">
        <Col>
          <h1>Contact Us</h1>
          <p className="text-muted">
            Have questions? Reach out to us and we’ll get back to you as soon as possible.
          </p>
        </Col>
      </Row>

      <Row className="mb-5 d-flex align-items-stretch">
        <Col md={6} className="d-flex">
          <Card className="p-3 shadow-sm h-100 w-100">
            <h3>Contact Information</h3>
            <p>
              <strong>Email:</strong> contact@truenorthplm.com
              <br />
              {/* <strong>Phone:</strong> +1-234-567-8900 */}
            </p>
            <h4>Address</h4>
            <p>Phoenix, Arizona, USA</p>
            <h5>Opening Hours</h5>
            <p>
              Monday - Friday: 9 AM - 5 PM
              <br />
              Saturday: 10 AM - 2 PM
              <br />
              Sunday: Closed
            </p>
          </Card>
        </Col>

        <Col md={6} className="d-flex">
          <Card className="p-3 shadow-sm h-100 w-100">
            <h3>Send Us a Message</h3>
            {submitted && (
              <Alert variant="success">
                Thank you for reaching out! We'll get back to you shortly.
              </Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Your Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Your Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Your Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="w-100">
                Submit
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <h3 className="mb-3">Our Location</h3>
          <iframe
            title="Google Map"
            className="map-frame"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d425342.78995919094!2d-112.45469269728159!3d33.60554975157907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b12ed50a179cb%3A0x8c69c7f8354a1bac!2sPhoenix%2C%20AZ!5e0!3m2!1sen!2sus!4v1731922362641!5m2!1sen!2sus"
            referrerPolicy="no-referrer-when-downgrade"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
