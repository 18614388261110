import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Offers from './pages/Offers/Offers';
import Contact from './pages/Contact/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const App = () => {
  useEffect(() => {
    document.title = "True North PLM";
  }, []);

  const websiteContent = [
    { title: 'Home', link: '/' },
    { title: 'About Us', link: '/about' },
    { title: 'Our Services', link: '/offers' },
    { title: 'Contact Us', link: '/contact' },
  ];

  return (
    <Router>
      <div id="root">
        <Header content={websiteContent} />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/offers" element={<Offers />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
