import React, { useState } from 'react';
import { Navbar, Nav, Container, Button, Offcanvas, Form, ListGroup } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FaSearch } from 'react-icons/fa';
import logo from 'assets/images/shared/logo.svg'
import './Header.css'

const Header = ({ content }) => {
  const [showSearch, setShowSearch] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  const handleShowSearch = () => setShowSearch(true);
  const handleCloseSearch = () => setShowSearch(false);

  // Filter content based on the search query
  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setQuery(searchTerm);

    if (searchTerm.length > 0) {
      const filteredResults = content.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setResults(filteredResults);
    } else {
      setResults([]);
    }
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
            <Navbar.Brand href="/">
                <img
                    src={logo}
                    alt="True North PLM Logo"
                    style={{ height: '70px', marginRight: '10px' }}
                />
                True North PLM
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
                <LinkContainer to="/">
                    <Nav.Link>Home</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/about">
                    <Nav.Link>About Us</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/offers">
                    <Nav.Link>Offers</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/contact">
                    <Nav.Link>Contact</Nav.Link>
                </LinkContainer>
                <Button variant="outline-light" onClick={handleShowSearch} className='search-button ms-3'>
                    <FaSearch size={15} color="white" className='search-icon'/>
                    Search
                </Button>
            </Nav>
            </Navbar.Collapse>
        </Container>
        <Offcanvas
            show={showSearch}
            onHide={handleCloseSearch}
            placement="top"
            style={{ height: '200px' }}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Search</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {/* Search Bar */}
                <Form.Control
                    type="text"
                    placeholder="Search..."
                    value={query}
                    onChange={handleSearch}
                    autoFocus
                />

                {/* Display Search Results */}
                {results.length > 0 && (
                    <ListGroup variant="flush" className="mt-3">
                    {results.map((result, index) => (
                        <ListGroup.Item key={index}>
                        <a href={result.link}>{result.title}</a>
                        </ListGroup.Item>
                    ))}
                    </ListGroup>
                )}
            </Offcanvas.Body>
      </Offcanvas>
    </Navbar>
  );
};

export default Header;
