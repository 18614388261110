export const linkedinPosts = [
    "7262664604734967809",
    "7263946980698042369",
    "7263014648482799616",
    "7262669805915193345",
    "7262667734985981952",
    // "7263947478578659329",
    // "7257871168261533696",
    "7257889643809955840",
    "7257833165283631104",
    "7257805924218646529",
  ];