import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import './LinkedInCarousel.css'
import {FaLinkedin} from "react-icons/fa6";

const LinkedInEmbed = ({ postUrl }) => {
  return (
    <div className="linkedin-post-container">
      <iframe
        title="LinkedIn Post"
        src={`https://www.linkedin.com/embed/feed/update/urn:li:share:${postUrl}`}
        width="100%"
        height="1000"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};

const LinkedInCarousel = ({ postUrls }) => {
  const [chunkSize, setChunkSize] = useState(3);

  // Function to update chunk size based on screen width
  const updateChunkSize = () => {
    setChunkSize(window.innerWidth < 768 ? 1 : 3); // 1 post for screens < 768px, else 3 posts
  };

  useEffect(() => {
    // Set initial chunk size
    updateChunkSize();

    // Add resize event listener
    window.addEventListener('resize', updateChunkSize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateChunkSize);
    };
  }, []);

  // Function to split the postUrls array into chunks
  const chunkArray = (array, size) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  };

  // Create chunks of LinkedIn posts (3 per chunk)
  const postChunks = chunkArray(postUrls, chunkSize);

  return (
    <section className="py-5">
      <div className="container">
        <div className="linkedin-carousel-container">
          <h2 className="text-center mb-4">
            Follow us on 
            <a 
                href="https://www.linkedin.com/company/true-north-plm" 
                target="_blank" 
                rel="noopener noreferrer"
                className="title-linkedin-icon"
            >
              <FaLinkedin size={40} />
            </a>
          </h2>
          <Carousel>
            {postChunks.map((chunk, index) => (
              <Carousel.Item key={index}>
                <div className={`d-flex justify-content-${chunkSize === 1 ? 'center' : 'between'}`}>
                  {chunk.map((postUrl, idx) => (
                    <div className="col" key={idx}>
                      <LinkedInEmbed postUrl={postUrl} />
                    </div>
                  ))}
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default LinkedInCarousel;
